// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/edwin.morris/Personal Github/ehmorris.com/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-featdrake-js": () => import("/Users/edwin.morris/Personal Github/ehmorris.com/src/pages/featdrake.js" /* webpackChunkName: "component---src-pages-featdrake-js" */),
  "component---src-pages-geniusoverview-js": () => import("/Users/edwin.morris/Personal Github/ehmorris.com/src/pages/geniusoverview.js" /* webpackChunkName: "component---src-pages-geniusoverview-js" */),
  "component---src-pages-homepage-js": () => import("/Users/edwin.morris/Personal Github/ehmorris.com/src/pages/homepage.js" /* webpackChunkName: "component---src-pages-homepage-js" */),
  "component---src-pages-index-js": () => import("/Users/edwin.morris/Personal Github/ehmorris.com/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keepsakes-js": () => import("/Users/edwin.morris/Personal Github/ehmorris.com/src/pages/keepsakes.js" /* webpackChunkName: "component---src-pages-keepsakes-js" */),
  "component---src-pages-lyriccardgenerator-index-js": () => import("/Users/edwin.morris/Personal Github/ehmorris.com/src/pages/lyriccardgenerator/index.js" /* webpackChunkName: "component---src-pages-lyriccardgenerator-index-js" */),
  "component---src-pages-lyriccardgenerator-tool-js": () => import("/Users/edwin.morris/Personal Github/ehmorris.com/src/pages/lyriccardgenerator/tool.js" /* webpackChunkName: "component---src-pages-lyriccardgenerator-tool-js" */),
  "component---src-pages-origamihue-js": () => import("/Users/edwin.morris/Personal Github/ehmorris.com/src/pages/origamihue.js" /* webpackChunkName: "component---src-pages-origamihue-js" */),
  "component---src-pages-songstories-js": () => import("/Users/edwin.morris/Personal Github/ehmorris.com/src/pages/songstories.js" /* webpackChunkName: "component---src-pages-songstories-js" */),
  "component---src-pages-styleguides-js": () => import("/Users/edwin.morris/Personal Github/ehmorris.com/src/pages/styleguides.js" /* webpackChunkName: "component---src-pages-styleguides-js" */),
  "component---src-pages-videonotes-js": () => import("/Users/edwin.morris/Personal Github/ehmorris.com/src/pages/videonotes.js" /* webpackChunkName: "component---src-pages-videonotes-js" */),
  "component---src-pages-xxx-js": () => import("/Users/edwin.morris/Personal Github/ehmorris.com/src/pages/xxx.js" /* webpackChunkName: "component---src-pages-xxx-js" */)
}

